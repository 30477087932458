import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';

import { OurClients } from '../components/Clients/OurClients';
import { Root } from '../components/Root';
import { Heading } from '../components/UI/Heading';
import { Text } from '../components/UI/Text';
import { Breakpoint } from '../types';
import { forBreakpoint } from '../utils/forBreakpoint';

export const query = graphql`
query teamMembers {
    teamMembers: allSanityTeam(filter: {Order: {gt: 0}}) {
      edges {
        node {
          Name
          Role
          Photo {
            asset {
              fluid(maxWidth: 800) {
                aspectRatio
                base64
                src
                srcSet
                sizes
              }
            },
            Alt
          }
        }
      }
    }
    allSanityClients {
      nodes {
        Photo {
          asset {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        },
        Name,
        Url
      }
    }
  }
`;

export default ({ data }) => {
  const members = data.teamMembers.edges;
  const clients = data.allSanityClients.nodes;

  return (
    <Root>
      <Heading type="h2">Team</Heading>
      <TeamBlocks>
        {members.map((edge, index) => {
          const member = edge.node;
          return (
            <TeamBlock key={index}>
              <ImageContainer>
                <Image
                  fluid={member.Photo.asset.fluid}
                  alt={member.Photo.alt}
                />
              </ImageContainer>
              <Text asDiv mode="normal">{member.Name}</Text>
              <Role asDiv mode="normal">{member.Role}</Role>
            </TeamBlock>
          );
        })}
      </TeamBlocks>
      <OurClients clients={clients} />
    </Root>
  );
};


const TeamBlocks = styled.div`
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 5rem;
`;

const TeamBlock = styled.div`
  width: 100%;
  
  ${forBreakpoint(Breakpoint.TabletPortrait,
  css`
    width: 33%;
  `)};

  min-width: 200px;
  margin-bottom: 2rem;
  margin-left: 0 auto;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 250px;
  margin-bottom: 1rem;
`;

const Image = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Role = styled(Text)`
  color: #A5C7C6;
  margin-top: 0.2rem;
`;
